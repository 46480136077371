import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonValidator } from '../../../shared/validators/common.validator';
import { AuthService } from '../../service/auth-service';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../../service/profile-service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  public userLoginForm!: UntypedFormGroup;
  loading: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService, private userService: ProfileService,
    private toastrService: ToastrService, private profile: ProfileService,
    private cookieService: CookieService
  ) { }


  ngOnInit(): void {
    // Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    this.userLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, CommonValidator.email]],
      password: ['', [Validators.required, Validators.maxLength(50)]],
      rememberMe: [false],
    });

  }
  get f() {
    return this.userLoginForm.controls;
  }

  onLogin() {
    if (this.userLoginForm.invalid) {
      this.userLoginForm.markAllAsTouched();
      return;
    }
    this.loading = true;

    this.authService.login(this.userLoginForm.value).subscribe(
      (res: any) => {
        if (res.success) {
          console.log(res, 'loginresponse');
          this.loading = false;

          if (this.userLoginForm.value?.rememberMe) {
            localStorage.setItem('rememberMe', "1");
          } else {
            localStorage.setItem('rememberMe', "0");
          }

          localStorage.setItem('token', res.token?.accessToken);
          localStorage.setItem('refreshToken', res.token?.refreshToken);
          localStorage.setItem('expiresIn', res.token?.expiresIn);

          localStorage.setItem('email', res.user.email);
          localStorage.setItem('UserId', res.user.id);

          const rememberMe = (document.getElementById('remember') as HTMLInputElement).checked;
          if (rememberMe) {
            this.cookieService.set('email', this.userLoginForm.get('email')?.value, 365); // Store for 365 days
            this.cookieService.set('password', this.userLoginForm.get('password')?.value, 365);
          } else {
            this.cookieService.delete('email');
            this.cookieService.delete('password');
          }
          // this.router.navigate(['/user/statistics']);
          this.toastrService.success(res.message, "Success", {
            progressBar: true,
            // toastClass: "toast ngx-toastr",
            closeButton: true,
            timeOut: 3000,
          });
          this.getUserDtl()
        }
      }, (err: any) => {
        this.loading = false;
        this.toastrService.error(err.error.message, "Error!", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
        });
      }
    );

  }

  getUserDtl() {
    this.userService.getCurrentUser().subscribe((res: any) => {
      if (res.success) {
        this.authService.userProfile = res.user
        this.profile.userProfile = res.user
        localStorage.setItem('role', res.user.role_id);
        // document.documentElement.style.setProperty('--sidebarPrimary', res.user.primary_color);
        // document.documentElement.style.setProperty('--sidebarSecondary', res.user.secondary_color);
        console.log(this.authService.userProfile)
        if (res.user.role_id == 1) {
          this.router.navigate(['/admin/dashboard']);
        } else {
          this.router.navigate(['/user/statistics']);
        }
      }
    })
  }
  togglePasswordVisibility(fieldId: string) {
    const field = document.getElementById(fieldId) as HTMLInputElement;
    if (field) {
      const fieldType = field.type === 'password' ? 'text' : 'password';
      field.type = fieldType;

      if (fieldId === 'password') {
        this.showPassword = fieldType === 'text';
      } else if (fieldId === 'confirmPassword') {
        this.showConfirmPassword = fieldType === 'text';
      }
    }
  }
}
