import { Component, inject, OnInit } from '@angular/core';
import { injectStripe } from 'ngx-stripe';
import { PaymentStripeServiceService } from '../../service/payment/payment-stripe-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentpopupComponent } from '../../../shared/paymentpopup/paymentpopup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-subscription-confirmation',
  templateUrl: './payment-subscription-confirmation.component.html',
  styleUrl: './payment-subscription-confirmation.component.scss'
})
export class PaymentSubscriptionConfirmationComponent implements OnInit{



  private readonly stripeService = inject(PaymentStripeServiceService);
  readonly stripe = injectStripe(this.stripeService.StripePublicKey);


  constructor(private route: ActivatedRoute,private modalService: NgbModal,private router: Router,private spinner :NgxSpinnerService,
    private paymentService :PaymentStripeServiceService,private toaster:ToastrService
  ) {}

  ngOnInit(): void {
    this.spinner.show()
    // Subscribe to query parameters
    this.route.paramMap.subscribe(params => {
      const clientSecret = params.get('client_secret');  // Retrieve 'client_secret' from the URL
      const subscriptionId = params.get('subscriptionId')
      console.log('Client Secret:', clientSecret);
      console.log('subscriptionId:', subscriptionId);
    
      // If clientSecret exists, call retrieveStatus or confirmPayment
      if (clientSecret) {
        console.log('Client Secret exists:', clientSecret);
    
        // Call the method to retrieve payment status or confirm payment
        this.checkActive(subscriptionId,clientSecret);
        // Optionally, you can call confirmPayment if needed
        // this.confirmPayment(clientSecret);
      }
    });
  }

    checkActive(subId:any,clientId:any){
      const payload = {
        subscriptionId: subId,
      }
      this.paymentService.checkActive(payload).subscribe((response)=>{
        if(response.success && response.data.subscriptionStatus ==='past_due'){
          this.retrieveStatus(clientId)
          console.log(response)
        }else if(response.success && response.data.subscriptionStatus ==='incomplete'){
          this.retrieveStatus(response.data.client_secret)
        }else if(response.success && response.data.subscriptionStatus ==='active'){
          this.spinner.hide()
          this.router.navigate(['/signin']);
        }
      },
    (error)=>{
      this.spinner.hide();
    this.toaster.error(error,"Error!",{
            progressBar: true,
            // toastClass: "toast ngx-toastr",
            closeButton: true,
            timeOut: 3000,
          })

          this.router.navigate(['/home']);
    })

    }

  retrieveStatus(clientSecret:any){
    this.stripe.retrievePaymentIntent(clientSecret).subscribe((result)=>{
      console.log(result)
      if(result.error){
        this.spinner.hide()
        const res = {
          success:false
        }
        this.popUpModalPayment(false, res, result.error.payment_intent?.last_payment_error?.message ||result.error.code,true)
      }
      else if(result.paymentIntent?.status === 'succeeded'){
        const res = {
          success:true
        }
        const modalRef = this.popUpModalPayment(true, res,'Already Subscribed')
        setTimeout(() => {
          modalRef.close()
          this.router.navigate(['/signin']);
        }, 3000);
      }
      else if(result.paymentIntent?.status === 
        "requires_confirmation" || '"requires_action"' ){
          this.confirmPayment(clientSecret)
      }else{
        this.confirmPayment(clientSecret)
      }
    },
  error=>{
    console.log(error)
    this.spinner.hide()
    const res = {
      success:false
    }
    this.popUpModalPayment(false, res, error,true)
  })
  
  }
confirmPayment(clientSecret:any){
  this.spinner.hide()
  this.stripe.confirmCardPayment(
    clientSecret
  ).subscribe(result => {
    console.log(result)
    if (result.error) {
      console.log(result)
      const res = {
        success:false
      }
      this.popUpModalPayment(false, res, result.error.payment_intent?.last_payment_error?.message ||result.error.code,true)

    }else if (result.paymentIntent?.status === 'succeeded' ) {
      
      const res = {
        success:true
      }
      const modalRef = this.popUpModalPayment(true, res)
      setTimeout(() => {
        modalRef.close()
        this.router.navigate(['/user/statistics']);
      }, 3000);
    }

  },
    error => {
      
      const res = {
        success:false
      }
      this.popUpModalPayment(false, res, error.error.message,true)

    })
}


popUpModalPayment(paymentStatus: any, subStatus: any, errormsg?: any,confirmSubscription?:any) {
  const modalRef = this.modalService.open(PaymentpopupComponent, {
    scrollable: true,
    backdrop: 'static',
    windowClass: 'modal-small-width modal-micro fade modal-subscribe-success',
    centered: true
  });
  modalRef.componentInstance.paymentStatus = paymentStatus;
  modalRef.componentInstance.subStatus = subStatus;
  modalRef.componentInstance.errormsg = errormsg;
  modalRef.componentInstance.confirmSubscription = confirmSubscription;
  return modalRef
}
   


}
