import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth-service';
import { ProfileService } from '../../service/profile-service';
import { ToastrService } from 'ngx-toastr';
import { CommonValidator } from '../../../shared/validators/common.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  public userLoginForm!: UntypedFormGroup;
  loading:boolean = false;

  constructor(
    private router:Router,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private userService:ProfileService,
    private toastrService: ToastrService,
    private profile:ProfileService
  ){}


  ngOnInit(): void {
		this.userLoginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email, CommonValidator.email]]
		});

	}
  get f() {
		return this.userLoginForm.controls;
	}

  onSubmit(){
    if (this.userLoginForm.invalid) {
      this.userLoginForm.markAllAsTouched();
      return;
    }
    this.loading = true;
    this.authService.changePasswordRequest(this.userLoginForm.value).subscribe(res => {
      if(res.success){
        this.loading = false;
        const successMessage = "Reset password E-mail sent successfully";
        this.toastrService.success(successMessage, "Success", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
          });
          this.router.navigate(['/signin']);
      }
    },(err:any)=>{
      this.loading = false;
      this.toastrService.error(err, "Error!", {
        progressBar: true,
        // toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 3000,
        });
    });
  }
}
