import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-password-expired',
  templateUrl: './reset-password-expired.component.html',
  styleUrl: './reset-password-expired.component.scss'
})
export class ResetPasswordExpiredComponent {

}
