<section class="section-terms-privacy">
    <div class="container  custom-container">
        <div class="row">
            <div class="col">
                <h1>Terms of Use</h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum optio assumenda beatae ratione asperiores, harum a fugit dolorum doloremque quaerat, iusto eaque voluptatem dolores temporibus ipsum quasi debitis. Sint, fugit aut deserunt officia consequuntur similique reiciendis repellat, iure quaerat consectetur blanditiis voluptas est magnam laudantium sapiente fugiat maiores nisi sit possimus magni repellendus. Nam, perspiciatis. A iure delectus voluptas obcaecati, aperiam, voluptatum omnis ipsum eveniet voluptatibus illum nemo beatae optio neque, necessitatibus temporibus? Vitae, deleniti. Autem cupiditate quaerat culpa facilis quo natus architecto amet, eligendi officia dolorum harum enim eos expedita? Dolorum minima quae officiis et sapiente fugiat doloribus exercitationem.</p>

                <ul>
                    <li>Lorem ipsum dolor sit amet.</li>
                    <li>Lorem ipsum dolor sit amet.</li>
                    <li>Lorem ipsum dolor sit amet.</li>
                    <li>Lorem ipsum dolor sit amet.</li>
                    <li>Lorem ipsum dolor sit amet.</li>
                </ul>

                <h2>Lorem ipsum dolor sit amet.</h2>

                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam eveniet ut, quos numquam dicta odit dolores aliquid excepturi inventore error, laboriosam atque voluptatibus dolorum vitae libero obcaecati sapiente, a ea pariatur consectetur earum sint doloremque. Numquam voluptates vero expedita. Pariatur quasi neque perferendis reiciendis eius porro harum quae, alias dicta exercitationem accusantium. Sunt necessitatibus, ab aperiam sit quae, distinctio, quo mollitia non quis laudantium dolores. Vel magnam a incidunt voluptatum harum autem rerum! <a href="#">Error officiis et odit commodi</a>  molestiae ut, quas facere aspernatur nobis numquam beatae aperiam, repellendus veritatis, quaerat hic iure voluptas. Quae ipsum magni alias maxime minima obcaecati!</p>

                <h3>Lorem ipsum dolor sit.</h3>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium laboriosam, soluta quidem at similique enim! Hic, illum et. Aliquam doloremque eveniet assumenda rerum repudiandae cumque dicta dolorum laboriosam, voluptatibus delectus maxime veniam ipsam reiciendis illum sint, fuga quas. Numquam, labore cumque. Ea, qui culpa ratione facilis optio sit aliquam quisquam quod eaque similique consectetur aliquid libero dignissimos numquam et distinctio illum ducimus animi natus ex! Recusandae obcaecati, consectetur libero enim possimus asperiores unde nulla autem porro, explicabo quod, pariatur similique.</p>

                <h4>Lorem, ipsum dolor.</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias atque tempora sunt quia excepturi numquam obcaecati quam distinctio nihil reiciendis maxime iure laboriosam ipsa neque animi quidem reprehenderit in itaque, iste perferendis, aliquam rerum! Dolorem quas eveniet amet, accusantium eligendi odit earum at beatae eum ipsam rem obcaecati reprehenderit facere pariatur sit provident harum in nemo? <strong>veritatis error autem nisi pariatur optio asperiores quasi </strong> voluptatem dolore eligendi magni, omnis modi distinctio eius. Corrupti consequuntur repellendus tenetur pariatur reprehenderit maiores? Laborum culpa nisi facilis quod itaque eveniet, pariatur amet minus obcaecati.</p>
            </div>
        </div>
    </div>
</section>