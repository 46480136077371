import { Component, EventEmitter, HostListener, inject, Input, OnInit, Output, viewChild, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { AuthService } from '../../../service/auth-service';
import { NgbAccordionItem, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpActivationCodeModalComponent } from '../sign-up-activation-code-modal/sign-up-activation-code-modal.component';
import { PaymentStripeServiceService } from '../../../service/payment/payment-stripe-service.service';
import { PaymentComponent } from '../../payment/payment.component';
import { StepService } from '../../../../shared/step.service';
import { FormFieldService } from '../../../../shared/form-field.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentpopupComponent } from '../../../../shared/paymentpopup/paymentpopup.component';
import { injectStripe } from 'ngx-stripe';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ActivationService } from '../../../service/activation.service';
import { CommonValidator } from '../../../../shared/validators/common.validator';


@Component({
  selector: 'app-signup-billing-and-payment',
  templateUrl: './signup-billing-and-payment.component.html',
  styleUrl: './signup-billing-and-payment.component.scss'
})
export class SignupBillingAndPaymentComponent implements OnInit{
  
  @ViewChild(PaymentComponent) paymentComponent!: PaymentComponent; 
  @ViewChild(NgbAccordionItem) accordion!: NgbAccordionItem; 
  @ViewChild('secondAccordion') secondAccordion!: NgbAccordionItem;
  billingform!: FormGroup;
  personalInfoForm!: any;
 @Input() Showmodal:any;
  country: any = [];
  selectCountry: any = null;
  selectedPlanId: any;
  ClientSecret: any;
  customerId:any;
  setUpIntentId: any;
  PlanAmount:any;
  discountAmount:any=0;
  discountPercentage:any=0;
  coupon:any=null;
  appliedCoupon:any=null;
  paypalCoupon:any=null;
  personalInfoFormonreload:any
  loader:boolean=false
  private readonly stripeService = inject(PaymentStripeServiceService);
  readonly stripe = injectStripe(this.stripeService.StripePublicKey);
  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private paymentService: PaymentStripeServiceService, private toasterService: ToastrService,
    private stepService: StepService,
    private formService: FormFieldService,
    private spinner:NgxSpinnerService,
    private activationService:ActivationService
  ) {
    this.route.queryParams.subscribe((res:any)=>{
      console.log(res)
    })
   }
   
   dropImage: any | null = null;
  selectedCountry: any;
  file:any;

  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  countryISO = CountryISO;
  selectedCountryISO: any;
  selectedDialCodeDtls: any = {
    name: 'in',
    code: ''
  }

  ngOnInit(): void {
    this.getCountryList();
   
    this.billingform = this.fb.group({
      firstName: ['', [Validators.required, this.lettersOnlyValidator.bind(this)]],
      lastName: ['', [Validators.required, this.lettersOnlyValidator.bind(this)]],
      phoneNumber: [null],
      apartment: ['', [Validators.required, this.trimValidator.bind(this), Validators.pattern(/^[\w /-]*$/)]],
      address: ['', [Validators.required, this.trimValidator.bind(this)]],
      country: ['', Validators.required],
      city: ['', [Validators.required, this.trimValidator.bind(this), this.lettersOnlyValidator]],
      state: ['', [Validators.required, this.trimValidator.bind(this), this.lettersOnlyValidator]],
      postalCode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern('^[0-9]*$'), // Ensures only numeric values
          // this.postalNumberValidator // Custom validator for exact 6 digits
        ]
      ],
      tos: [false, [Validators.requiredTrue]]
    });
    console.log(this.billingform)

    const ReloadBillingData = sessionStorage.getItem('billingFormData');
    console.log(ReloadBillingData)
    if(ReloadBillingData){
      const parsedData = JSON.parse(ReloadBillingData);
      console.log(parsedData)
      this.prefillBillingFormOnReload(parsedData);
    }

   console.log("calleddd oninitt")
   
    this.formService.getPersonalInfoFormData().subscribe(data => {
      if (data) {
        console.log(data);
        this.personalInfoForm = data;
        sessionStorage.setItem('personalInfoFormData', JSON.stringify(data.getRawValue()));
        
        this.prefillBillingForm(this.personalInfoForm);
        
        
      }else  if(sessionStorage.getItem('personalInfoFormData') && !sessionStorage.getItem('billingFormData')){
        this.personalInfoFormonreload = sessionStorage.getItem('personalInfoFormData');
        this.loader=true
        // this.spinner.show()
          if (this.personalInfoFormonreload) {
            
            this.personalInfoForm = JSON.parse(this.personalInfoFormonreload)
            this.getCountryList();
        
            
          }
      }
      
    });

   

    // to get a logo details
    this.formService.getDropImageData().subscribe(data => {
      if (data) {
        this.dropImage = data;
        sessionStorage.setItem('dropImageData', JSON.stringify(data));
        // You can use this.dropImage here if needed
      }else{
        const storedData = sessionStorage.getItem('dropImageData');
          if (storedData) {
            this.dropImage = JSON.parse(storedData);

            // You can use this.dropImage here if needed
          }
      }
    });
    
    // To get a selected plan id 
    this.stepService.getSelectedPlanId().subscribe(planId => {
      if(planId){
        this.selectedPlanId = planId;
        sessionStorage.setItem('selectedPlanId', JSON.stringify(planId));
      }else{
        const storedData = sessionStorage.getItem('selectedPlanId');
          if (storedData) {
            this.selectedPlanId = JSON.parse(storedData);

            // You can use this.dropImage here if needed
          }
      }
     
      console.log('Selected Plan ID:', this.selectedPlanId);
      if (planId && sessionStorage.getItem('paymentData')===null) {
        console.log(this.selectedPlanId)
        console.log(this.personalInfoForm)
        const payload = {
          email: this.personalInfoForm.value?this.personalInfoForm.value.email:this.personalInfoForm.email,
          firstName: this.personalInfoForm.value?this.personalInfoForm.value.firstName:this.personalInfoForm.firstName,
          lastName: this.personalInfoForm.value?this.personalInfoForm.value.lastName:this.personalInfoForm.lastName,
          phone: this.personalInfoForm.value? this.personalInfoForm.value.phoneNumber.e164Number:this.personalInfoForm.phoneNumber.e164Number,
        }
        this.getClientSecret(this.selectedPlanId, payload);
      }
    });

    const storedPaymentData = sessionStorage.getItem('paymentData');
    if (storedPaymentData) {
      const paymentData = JSON.parse(storedPaymentData);
      console.log(paymentData)
        this.ClientSecret = paymentData.clientSecret;
         this.setUpIntentId = paymentData.setUpIntentId;
         this.customerId = paymentData.customerId;
       
      }


      this.stepService.getPlanData().subscribe((planData:any) => {
        console.log(planData)
        this.discountAmount=0;
         if(planData){
          this.PlanAmount = planData.amount;
          const StoredCoupon = sessionStorage.getItem('coupon')
        if(StoredCoupon){
         const CouponDetails = JSON.parse(StoredCoupon);
         const discount = this.PlanAmount*(CouponDetails.discount/100)
          this.discountAmount = discount;
          this.discountPercentage=CouponDetails.discount;
          this.coupon=CouponDetails.couponId
          this.appliedCoupon=CouponDetails.couponCode

      }

         }else{
          const StoredplanData = sessionStorage.getItem('plan');
          if (StoredplanData) {
            const StoredPlanAmount = JSON.parse(StoredplanData);
            
            this.PlanAmount=StoredPlanAmount.amount
         }
         const StoredCoupon = sessionStorage.getItem('coupon')
       if(StoredCoupon){
         const CouponDetails = JSON.parse(StoredCoupon);
         const discount = this.PlanAmount*(CouponDetails.discount/100)
          this.discountAmount = discount;
          this.discountPercentage=CouponDetails.discount;
          this.coupon=CouponDetails.couponId
          this.appliedCoupon=CouponDetails.couponCode

      }
        }
      })

      
      if(this.Showmodal){
        
        this.payPop()
      }
   
     

    }

    trimValidator(control: AbstractControl): ValidationErrors | null {
      const value = control.value?.toString();
      // Check if the value is empty or contains only spaces
      if (value && (value.startsWith(' ') || !value.trim().length)) {
        return { trimError: true };
      }
      return null;
    }

  noSpaceValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.toString().trim();

    // Check if the value starts with or ends with a space, or contains non-letter characters
    const hasLeadingOrTrailingSpaces = value !== control.value;
    const hasInvalidSpaces = /\s{2,}/.test(value); // Checks for multiple consecutive spaces
    const hasOnlyLetters = /^[a-zA-Z\s]*$/.test(value); // Checks for letters and spaces only

    if (hasLeadingOrTrailingSpaces || hasInvalidSpaces || !hasOnlyLetters || value.length === 0) {
      return { noSpace: true };
    }

    return null;
  }


  restrictSpecialCharacters(event: any) { 
    if (event.target.id === 'country-search-box') {
      // This is the country search box, no replacement needed
      return;
    }
    const input = event.target;
    const cleanValue = input.value.replace(/[^0-9+]/g, ''); // Allows only numbers, spaces, and the plus sign
    input.value = cleanValue;
    const minLength = 4;
    const maxLength = 20;
    const phoneNumberControl = this.billingform.get('phoneNumber');
    
    // Extract and clean the phone number value
    let phoneNumber = phoneNumberControl?.value?.number || '';
    phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    if (phoneNumber.length === 0) {
      phoneNumberControl?.setErrors({ required: true });
    } else if (phoneNumber.length < minLength || phoneNumber.length > maxLength) {
      phoneNumberControl?.setErrors({ invalidLength: true });
    } else {
      phoneNumberControl?.setErrors(null); // Clear any existing errors if valid
    }

  }


  lettersOnlyValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.toString().trim();

    // Check if the value starts with a space or contains non-letter characters
    const startsWithSpace = control.value?.toString().startsWith(' ');
    const hasOnlyLetters = /^[a-zA-Z\s]*$/.test(value);

    if (startsWithSpace || !hasOnlyLetters ) {
      return { lettersOnly: true };
    }

    return null;
  }

  
  prefillBillingForm(personalInfoData: any): void {

    if (personalInfoData) {
      const cleanedPhoneNumber = this.cleanPhoneNumber(personalInfoData.value?personalInfoData.value.phoneNumber:personalInfoData.phoneNumber);
      const countrycode = personalInfoData.value?personalInfoData.value.phoneNumber.countryCode:personalInfoData.phoneNumber.countryCode;
      this.selectCountry = this.country.find((value:any) => countrycode && value.iso2 === countrycode);

      this.billingform.patchValue({
        firstName: personalInfoData.value?personalInfoData.value.firstName:personalInfoData.firstName || '',
        lastName: personalInfoData.value?personalInfoData.value.lastName:personalInfoData.lastName || '',
        phoneNumber: { number: cleanedPhoneNumber,
           dialCode: personalInfoData.value?personalInfoData.value.phoneNumber.dialCode:personalInfoData.phoneNumber.dialCode,
           countryCode: personalInfoData.value?personalInfoData.value.phoneNumber.countryCode:personalInfoData.phoneNumber.countryCode },
          //  country: personalInfoData.value? personalInfoData.value.country:personalInfoData.country || '',
        // Set other fields as needed
        country: this.selectCountry,
      });
    }
  }
  prefillBillingFormOnReload(personalInfoData: any): void {
    if (personalInfoData) {
      console.log(personalInfoData)
      // Clean and extract phone number components
      const cleanedPhoneNumber = this.cleanPhoneNumber(personalInfoData?.phoneNumber || '');
      const dialCode = personalInfoData?.phoneNumber?.dialCode || '';
      const countryCode = personalInfoData?.phoneNumber?.countryCode || '';
     this.selectCountry = personalInfoData?.country;
      // Patch the form values
      this.billingform.patchValue({
        firstName: personalInfoData?.firstName ? personalInfoData?.firstName : '',
        lastName: personalInfoData?.lastName || '',
        phoneNumber: { number: cleanedPhoneNumber, dialCode: dialCode, countryCode: countryCode },
        apartment: personalInfoData?.apartment || '',
        address: personalInfoData?.address || '',
        country: personalInfoData?.country || '',
        city: personalInfoData?.city || '',
        state: personalInfoData?.state || '',
        postalCode: personalInfoData?.postalCode || ''
      });
    }
  }
  cleanPhoneNumber(phoneData: any): string {
    if (phoneData && phoneData.number) {
      let phone = phoneData.number || '';
      let cleanPhoneNumber = phone.replace(/\D/g, ''); // Remove non-digit characters

      if (cleanPhoneNumber.startsWith('0')) {
        cleanPhoneNumber = cleanPhoneNumber.slice(1); // Remove leading zero
      }

      return cleanPhoneNumber;
    }
    return '';
  }

  getClientSecret(planId: any,payload:any) {
    this.paymentService.createSetupIntent(planId,payload).subscribe((res: any) => {
      const combinedData = {
        clientSecret: res.data.client_secret,
        setUpIntentId: res.data.setupIntent,
        customerId: res.data.customerId,
        returnUrl: res.data.return_url
      };

      // Store the combined data in local storage
      sessionStorage.setItem('paymentData', JSON.stringify(combinedData));
      this.ClientSecret = res?.data?.client_secret;
      this.setUpIntentId = res?.data?.setupIntent;
      this.customerId = res?.data?.customerId
      console.log(res.data.client_secret);
      console.log(res.data.return_url);
    }, (err: any) => {
      console.log(err)
      this.toasterService.error(err, "Error!", {
        progressBar: true,
        // toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 3000,
      });
      // this.isLoading = false;
    })
  }

  postalNumberValidator(control: AbstractControl): ValidationErrors | null {
    const postalNumber = control.value?.toString(); // Convert to string for length check

    if (postalNumber.length !== 6) {
      return { invalidLength: true };
    }

    return null;
  }


  maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = control.value ? control.value.toString().length <= maxLength : true;
      return isValid ? null : { 'maxLength': { value: control.value } };
    };
  }

  getCountryList() {
   
    this.authService.countryist().subscribe((res: any) => {
      this.country = res.data;
      if(this.country){
        // this.spinner.hide()
        this.loader=false
        if(this.personalInfoFormonreload){
          this.prefillBillingForm(JSON.parse(this.personalInfoFormonreload));
        }
        
      }
      console.log(this.country);
    })
  }

  phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value?.number || '';
    if (typeof phoneNumber !== 'string') {
      return null;
    }
    let cleanedPhone = phoneNumber.replace(/\D/g, '');
    if (cleanedPhone.startsWith('0')) {
      cleanedPhone = cleanedPhone.slice(1);
    }
    if (cleanedPhone.length !== 10) {
      return { invalidLength: true };
    }
    return null;
  }

  goToPreviousStep() {
    this.stepService.setStep('subscription-plan');
    sessionStorage.setItem('currentStep','subscription-plan')
    // this.router.navigate(['/signup/subscription-plan']);
  }

  // getFieldError(controlName: string, error: string): boolean {
  //   console.log(this.billingform.get(controlName)?.hasError(error))
  //   return this.billingform.get(controlName)?.hasError(error) || false;
  // }

  onSubmit(): void {

    const minLength = 4;
    const maxLength = 20;
    const phoneNumberControl = this.billingform.get('phoneNumber');
    
    // Extract and clean the phone number value
    let phoneNumber = phoneNumberControl?.value?.number || '';
    phoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    if (phoneNumber.length === 0) {
      phoneNumberControl?.setErrors({ required: true });
    } else if (phoneNumber.length < minLength || phoneNumber.length > maxLength) {
      phoneNumberControl?.setErrors({ invalidLength: true });
    } else {
      phoneNumberControl?.setErrors(null); // Clear any existing errors if valid
    }
    console.log(this.billingform)
    if (this.billingform.valid && this.billingform.value.tos) {

    const formValues = this.billingform.getRawValue();
    
    sessionStorage.setItem('billingFormData', JSON.stringify(formValues));
      this.paymentComponent.createSubscription(this.secondAccordion)
      console.log('Form submitted successfully');
    } else{
      this.billingform.markAllAsTouched();
      const formControls = this.billingform.controls;
      let isInvalid = false;

      for (let controlName in formControls) {
        if (controlName !== 'tos' && formControls[controlName].invalid) {
          isInvalid = true;
          break;
        }
      }

      if (isInvalid) {
        this.accordion.expand();
      }
      
    }

  }

  enterActivationCode(): void {
    const modalRef = this.modalService.open(SignUpActivationCodeModalComponent, {
      scrollable: true,
      backdrop: 'static',
      windowClass: 'modal-small-width modal-micro modal-activatn',
      centered: true
    });

    modalRef.componentInstance.paymentIntentId = this.setUpIntentId;
    modalRef.componentInstance.productId = this.selectedPlanId;
    modalRef.componentInstance.coupneCode.subscribe((res:any)=>{
      console.log(res)
      if(res.couponId!==null){
      const discount = this.PlanAmount*(res.discount/100)
      this.discountAmount = discount;
      this.discountPercentage=res.discount;
      this.coupon=res.couponId
      this.appliedCoupon=res.couponCode
        setTimeout(() => {
          modalRef.close()
        }, 2000);
      }
      
    })

  }
 removeActivationCode(){
  this.coupon=null;
  this.discountAmount=0;
  this.discountPercentage=0;
  this.appliedCoupon=null;
  sessionStorage.removeItem('coupon')
 }

  updateCountry(e: any): void {
    console.log(e);
    this.billingform.patchValue({
      country: e
    });

    console.log(this.billingform);
  }

  payPop() {
    
    this.spinner.show();
    sessionStorage.setItem('paymentInProgress','true')
    window.addEventListener('beforeunload', this.unloadNotification.bind(this));
    this.route.queryParams.subscribe((res:any)=>{
      console.log(res)
      let val = res.setup_intent_client_secret;
      
    this.stripe.retrieveSetupIntent(
    val
  ).subscribe((res:any)=>{
    
    console.log(res)
    console.log(res.setupIntent.status)
    if(res.error){
      console.log("first",res.error.message)
      sessionStorage.removeItem('paymentInProgress')
      this.popUpModalPayment(false,res.error,res.error.message)
      this.spinner.hide()
    }
    else if(res.setupIntent.status === 'succeeded' && res.setupIntent.payment_method){
      const Cdata = sessionStorage.getItem('coupon')
      if(Cdata){
         this.paypalCoupon= JSON.parse(Cdata).couponId;
      }
      this.paymentComponent.paypalSub(res,this.paypalCoupon).subscribe((subRes: any) => {
        console.log(subRes.success);
        if(subRes.success){
          this.spinner.show()
          const modalRef = this.popUpModalPayment(true,subRes)
          setTimeout(()=>{
            this.paymentComponent.loginupdate(subRes,modalRef);
          },3000)
        }
        else if(subRes.error){ 
          sessionStorage.removeItem('paymentInProgress')
          const errorMessage = res.error.message;
          const parts = errorMessage.split(':');
          this.popUpModalPayment(false,subRes,parts.length > 1 ? parts[1].trim() : errorMessage);
        }
      },
      error=>{
        sessionStorage.removeItem('paymentInProgress')
        this.popUpModalPayment(false,error,error);
      }
    );
    
  
    }else if(res.setupIntent.status !== 'succeeded' && res.setupIntent.last_setup_error){
      sessionStorage.removeItem('paymentInProgress')
      console.log('second',res.setupIntent.last_setup_error.message)
      this.popUpModalPayment(false,res.setupIntent.last_setup_error,res.setupIntent.last_setup_error.message)
      this.spinner.hide()
    }
  },
  error=>{
    sessionStorage.removeItem('paymentInProgress')
    this.popUpModalPayment(false,error.error.message,error.error.message)
      this.spinner.hide()
  });
    })
    
  }


  popUpModalPayment(paymentStatus:any,subStatus:any,errormsg?:any){
    const modalRef = this.modalService.open(PaymentpopupComponent, {
      scrollable: true,
      backdrop: 'static',
      windowClass: 'modal-small-width modal-micro fade modal-subscribe-success',
      centered: true
    });
    modalRef.componentInstance.paymentStatus = paymentStatus;
    modalRef.componentInstance.subStatus = subStatus;
    modalRef.componentInstance.errormsg = errormsg;
    return modalRef
  }


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    console.log(sessionStorage.getItem('paymentInProgress'))
    if (sessionStorage.getItem('paymentInProgress')) {
      // Show a custom warning message (if supported by the browser)
      $event.returnValue = 'A payment is in progress. Are you sure you want to leave?';
    }
  }
}
