<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Activation code</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"><img
                src="../../../../../assets/img/modal-close.svg" alt=""></button>
    </div>

    <form action="" [formGroup]="activationCodeForm" (ngSubmit)="onSubmit()">
        <div class="modal-body modal-form">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group input-wrp">
                        <label for="" class="label">Activation Code <span class="text-danger">*</span> </label>

                        <input type="text" class="form-control" placeholder="Enter activation code"
                            formControlName="code">
                        <div *ngIf="f['code'].touched && f['code'].invalid" class="form-error">
                            <!-- <span *ngIf="f['code'].errors?.['noWhiteSpace']" class="err-msg">White spaces are not
                                allowed.</span> -->
                            <span *ngIf="!f['code'].errors?.['noWhiteSpace'] && f['code'].errors?.['required']"
                                class="err-msg">Activation code
                                is required.</span>
                            <!-- <span *ngIf="!f['code'].errors?.['noWhiteSpace'] && f['code'].errors?.['pattern']"
                                class="err-msg">Please
                                enter a valid activation code with 10 to 15 alphanumeric characters.</span> -->
                        </div>
                        <!-- <div *ngIf="f['code'].touched && f['code'].valid" class="form-error">
                            <span *ngIf="success.res" class="err-msg text-success">{{success.res}} You get
                                {{success.discount}} % discount</span>
                            <span *ngIf="error!=null" class="err-msg">{{error}} </span>

                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close()">Cancel</button>
            <button type="submit" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                <span *ngIf="!loading">Submit</span>
            </button>
        </div>
    </form>
</div>