import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../service/auth-service';
import { ToastrService } from 'ngx-toastr';
import { StepService } from '../../../../shared/step.service';
import { FormFieldService } from '../../../../shared/form-field.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpActivationCodeModalComponent } from '../sign-up-activation-code-modal/sign-up-activation-code-modal.component';

@Component({
  selector: 'app-signup-subscription-plan',
  templateUrl: './signup-subscription-plan.component.html',
  styleUrl: './signup-subscription-plan.component.scss'
})
export class SignupSubscriptionPlanComponent implements OnInit {

  plans: any[] = [];
  selectedPlanId: number | null = null;  // Add this property to store selected plan ID

  isLoading: boolean = true;

  personalInfoForm!: FormGroup;
  dropImage: any | null = null;
  isAddimgRemove: boolean = false;

  filteredPlans!: any[];
  isAnnual = true;
  planPeriod: string = 'annually'

  selectSubPlan: any;
  // need to clrear this once the plans added in stripe manually
  // defaultPlans: any[] = [  // Add default plans
  //   { id: 1, name: 'Basic Plan', price: 9.99 },
  //   { id: 2, name: 'Standard Plan', price: 19.99 },
  // ];

  constructor(private router: Router, private route: ActivatedRoute,
    private authService: AuthService, private toasterService: ToastrService,
    private stepService: StepService,
    private formService: FormFieldService,
    private modalService: NgbModal,

  ) { }

  ngOnInit(): void {
    let plan: any = sessionStorage.getItem('plan');
    if (plan != '') {
      let plan1 = JSON.parse(plan)
      this.planPeriod = plan1?.period == 'day' ? 'monthly' : 'annually'
      this.isAnnual = plan1?.period == 'day' ? false : true
      console.log(plan1, this.planPeriod, this.isAnnual)
      this.getPlansList();
      // You can use this.dropImage here if needed
    } else {
      this.getPlansList();
    }
    // localStorage.removeItem('coupon')
  }

  getPlansList(): void {
    this.isLoading = true;
    this.authService.getSubscriptionPlanList(this.planPeriod).subscribe(
      (res: any) => {
        if (res && res.plans && res.plans.length > 0) {
          this.plans = res.plans;
          console.log(this.plans)

          // this.selectedPlanId = this.plans[0].priceId;

          this.selectSubPlan = this.plans[0]
          this.plans.map((res: any) => {
            res.desc = res.description.split('.').filter((value: string) => value != '');
            // console.log(res);
          })
          // this.isAnnual = true;
          // this.filterPlans();
          const storedData = sessionStorage.getItem('plan');
          if (storedData) {
            this.selectedPlanId = JSON.parse(storedData).priceId;
            this.selectSubPlan = this.plans.find((value) => {
              return value.priceId == this.selectedPlanId
            })
            console.log(this.selectSubPlan)
            // You can use this.dropImage here if needed
          }

        }
        this.isLoading = false;
      }, (err: any) => {
        this.toasterService.error(err.error.message, "Error!", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
        });
        this.isLoading = false;
      }
    );
  }

  togglePeriod(event: any) {
    this.isAnnual = event.target.checked;
    // this.filterPlans();
    if (this.isAnnual) {
      this.planPeriod = "annually"
    } else {
      this.planPeriod = "monthly"
    }
    this.getPlansList()
  }

  filterPlans() {
    this.filteredPlans = this.plans.filter(plan => {
      const isMatch = this.isAnnual
        ? (plan.period === 'annual' || plan.period === 'week')
        : (plan.period === 'monthly' || plan.period === 'day');

      // console.log(`Plan Period: ${plan.period}, Is Match: ${isMatch}`);
      return isMatch;
    });

    // console.log('Filtered Plans:', this.filteredPlans);
  }


  goToPreviousStep() {
    // sessionStorage.removeItem('paymentData')
    this.stepService.setStep('personal-info');
    sessionStorage.setItem('currentStep', 'personal-info')
    // this.router.navigate(['/signup/personal-info']);

  }
  selectPlan(data: any) {
    sessionStorage.removeItem('paymentData')
    this.selectSubPlan = data;
    console.log(data)
  }
  goToNextStep() {
    if (this.selectedPlanId !== null) {
      console.log(this.selectSubPlan)
      this.stepService.setSelectedPlanId(this.selectedPlanId, this.selectSubPlan);
      sessionStorage.setItem('plan', JSON.stringify(this.selectSubPlan));
      this.stepService.setStep('billing-and-payment');
      sessionStorage.setItem('currentStep', 'billing-and-payment')
      // this.router.navigate(['/signup/billing-and-payment'], { queryParams: { planId: this.selectedPlanId } });
    } else {
      this.toasterService.error("Please select a plan.", "Error!", {
        progressBar: true,
        // toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 3000,
      });
    }
  }
  getFeaturesArray(features: any): string[] {
    return Object.values(features);
  }

  enterActivationCode(): void {
    this.selectedPlanId = null;
    const modalRef = this.modalService.open(SignUpActivationCodeModalComponent, {
      scrollable: true,
      backdrop: 'static',
      windowClass: 'modal-small-width modal-micro modal-activatn',
      centered: true
    });
  }

}
