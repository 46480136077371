<form action="#" class="login-form">
    <div class="form-wrp">
        <div class="form-steps step-2">
            <div class="title-wrp">
                <h3 class="form-title">
                    <span class="ico">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="frame" transform="translate(-108 -252)">
                                <path id="Vector"
                                    d="M4.75,0a4.746,4.746,0,0,0-.12,9.49.807.807,0,0,1,.22,0h.07A4.746,4.746,0,0,0,4.75,0Z"
                                    transform="translate(115.25 254)" fill="currentcolor" />
                                <path id="Vector-2" data-name="Vector"
                                    d="M12.12,1.395a9.929,9.929,0,0,0-10.15,0A3.947,3.947,0,0,0,0,4.625a3.914,3.914,0,0,0,1.96,3.21,9.239,9.239,0,0,0,5.08,1.41,9.239,9.239,0,0,0,5.08-1.41,3.945,3.945,0,0,0,1.96-3.23A3.937,3.937,0,0,0,12.12,1.395Z"
                                    transform="translate(112.96 264.755)" fill="currentcolor" />
                                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z"
                                    transform="translate(132 276) rotate(180)" fill="none" opacity="0" />
                            </g>
                        </svg>
                    </span>
                    <span class="txt">Choose Plan</span>
                </h3>

                <!-- <div class="plan-choose-duration-wrp">
                    <span class="label monthly">Monthly</span>
                    <div class="custom-switch">
                        <label class="switch"><input type="checkbox" class="" checked>
                            <div class="slider round"></div>
                        </label>
                    </div>
                    <span class="label annual active">Annual</span>

                    <span class="badge rounded-pill">Save 26%</span>
                </div> -->

                <div class="plan-choose-duration-wrp">
                    <span class="label monthly" [class.active]="!isAnnual">Monthly</span>
                    <div class="custom-switch">
                        <label class="switch">
                            <input type="checkbox" (change)="togglePeriod($event)" [checked]="isAnnual">
                            <div class="slider round"></div>
                        </label>
                    </div>
                    <span class="label annual" [class.active]="isAnnual">Annual</span>
                    <!-- <span class="badge rounded-pill">Save 26%</span> -->
                </div>
            </div>

            <!-- plan cards  -->
            <div class="input-wrp">
                <div class="row card-min-height">
                    <div *ngIf="isLoading; else planList" class="skeleton-container">

                        <div class="skeleton-card">
                            <div class="skeleton-header">
                                <ngx-skeleton-loader class="skeleton-price"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="skeleton-circle" count="1" appearance="circle"
                                    [theme]="{ left: '-15px', top: '-10px' }"></ngx-skeleton-loader>
                            </div>
                            <ngx-skeleton-loader class="skeleton-category"></ngx-skeleton-loader>
                            <ngx-skeleton-loader class="skeleton-line"
                                *ngFor="let i of [1,2,3,4,5,6,7]"></ngx-skeleton-loader>
                        </div>

                        <div class="skeleton-card">
                            <div class="skeleton-header">
                                <ngx-skeleton-loader class="skeleton-price"></ngx-skeleton-loader>
                                <ngx-skeleton-loader class="skeleton-circle" count="1" appearance="circle"
                                    [theme]="{ left: '-15px', top: '-10px' }"></ngx-skeleton-loader>
                            </div>
                            <ngx-skeleton-loader class="skeleton-category"></ngx-skeleton-loader>
                            <ngx-skeleton-loader class="skeleton-line"
                                *ngFor="let i of [1,2,3,4,5,6,7]"></ngx-skeleton-loader>
                        </div>

                    </div>


                    <ng-template #planList>
                        <!-- Your existing plan cards go here -->
                        <div class="col-xl-6" *ngFor="let plan of plans">
                            <div class="plan-card-checkbox">
                                <label [for]="'plan' + plan.id" class="custom-chk">
                                    <input type="radio" name="planCard" [id]="'plan' + plan.id" class="custom-chk-inp"
                                        [(ngModel)]="selectedPlanId" [value]="plan.priceId" (change)="selectPlan(plan)">
                                    <span class="radio"></span>

                                    <div class="plan-card">
                                        <div class="plan-head">
                                            <div class="plan-titles">
                                                <div class="txt">
                                                    <h3 class="service">{{ plan.name }}</h3>
                                                    <h3 class="rate">
                                                        <span class="amt">${{ plan.amount }}</span>
                                                        <span class="billcycle">/ {{ plan.period }}</span>
                                                    </h3>
                                                </div>
                                                <div class="ico">
                                                    <img src="../../../../assets/img/plan-icon.svg" alt="">
                                                </div>
                                            </div>

                                            <div class="plan-badge">
                                                <span class="ico">
                                                    <img src="../../../../assets/img/logo-icon-white.svg" alt="ico">
                                                </span>
                                                <span class="txt">{{ plan.description }} CATEGORY</span>
                                            </div>

                                        </div>

                                        <div class="plan-info">
                                            <ul class="plan-info-list">
                                                <li class="list-itm" *ngFor="let feature of getFeaturesArray(plan.features)">
                                                    <ng-container >
                                                        <span class="ico">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.959"
                                                                height="12.463" viewBox="0 0 10.959 12.463">
                                                                <g id="Group_60449" data-name="Group 60449"
                                                                    transform="translate(4210.169 -1551.285)">
                                                                    <path id="Path_15264" data-name="Path 15264"
                                                                        d="M227.356,465.77l.822-1.423,2.329,1.345-.821,1.422a.336.336,0,0,0,.354.5l1.574-.3.51,2.641-1.574.3A3.026,3.026,0,0,1,227.356,465.77Z"
                                                                        transform="translate(-4431.334 1086.938)"
                                                                        fill="currentcolor" fill-rule="evenodd" />
                                                                    <path id="Path_15265" data-name="Path 15265"
                                                                        d="M225.933,475.387l-.822,1.423-2.33-1.345.822-1.423a.336.336,0,0,0-.355-.5l-1.574.3-.509-2.641,1.574-.3A3.026,3.026,0,0,1,225.933,475.387Z"
                                                                        transform="translate(-4431.334 1086.938)"
                                                                        fill="currentcolor" fill-rule="evenodd" />
                                                                    <path id="Path_15266" data-name="Path 15266"
                                                                        d="M226,465.77l-.822-1.423-2.329,1.345.821,1.422a.337.337,0,0,1-.355.5l-1.574-.3-.508,2.641,1.573.3A3.026,3.026,0,0,0,226,465.77Z"
                                                                        transform="translate(-4431.334 1086.938)"
                                                                        fill="currentcolor" fill-rule="evenodd" />
                                                                    <path id="Path_15267" data-name="Path 15267"
                                                                        d="M227.287,475.387l.821,1.423,2.33-1.345-.821-1.423a.336.336,0,0,1,.354-.5l1.574.3.51-2.641-1.574-.3a3.026,3.026,0,0,0-3.194,4.484Z"
                                                                        transform="translate(-4431.334 1086.938)"
                                                                        fill="currentcolor" fill-rule="evenodd" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span class="txt">{{ feature }}</span>
                                                    </ng-container>
                                                </li>
                                                <!-- <li class="list-itm">
                                                    <span class="ico">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.959"
                                                            height="12.463" viewBox="0 0 10.959 12.463">
                                                            <g id="Group_60449" data-name="Group 60449"
                                                                transform="translate(4210.169 -1551.285)">
                                                                <path id="Path_15264" data-name="Path 15264"
                                                                    d="M227.356,465.77l.822-1.423,2.329,1.345-.821,1.422a.336.336,0,0,0,.354.5l1.574-.3.51,2.641-1.574.3A3.026,3.026,0,0,1,227.356,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15265" data-name="Path 15265"
                                                                    d="M225.933,475.387l-.822,1.423-2.33-1.345.822-1.423a.336.336,0,0,0-.355-.5l-1.574.3-.509-2.641,1.574-.3A3.026,3.026,0,0,1,225.933,475.387Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15266" data-name="Path 15266"
                                                                    d="M226,465.77l-.822-1.423-2.329,1.345.821,1.422a.337.337,0,0,1-.355.5l-1.574-.3-.508,2.641,1.573.3A3.026,3.026,0,0,0,226,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15267" data-name="Path 15267"
                                                                    d="M227.287,475.387l.821,1.423,2.33-1.345-.821-1.423a.336.336,0,0,1,.354-.5l1.574.3.51-2.641-1.574-.3a3.026,3.026,0,0,0-3.194,4.484Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="txt">Ranking keyword positions for your domain</span>

                                                </li>
                                                <li class="list-itm">
                                                    <span class="ico">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.959"
                                                            height="12.463" viewBox="0 0 10.959 12.463">
                                                            <g id="Group_60449" data-name="Group 60449"
                                                                transform="translate(4210.169 -1551.285)">
                                                                <path id="Path_15264" data-name="Path 15264"
                                                                    d="M227.356,465.77l.822-1.423,2.329,1.345-.821,1.422a.336.336,0,0,0,.354.5l1.574-.3.51,2.641-1.574.3A3.026,3.026,0,0,1,227.356,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15265" data-name="Path 15265"
                                                                    d="M225.933,475.387l-.822,1.423-2.33-1.345.822-1.423a.336.336,0,0,0-.355-.5l-1.574.3-.509-2.641,1.574-.3A3.026,3.026,0,0,1,225.933,475.387Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15266" data-name="Path 15266"
                                                                    d="M226,465.77l-.822-1.423-2.329,1.345.821,1.422a.337.337,0,0,1-.355.5l-1.574-.3-.508,2.641,1.573.3A3.026,3.026,0,0,0,226,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15267" data-name="Path 15267"
                                                                    d="M227.287,475.387l.821,1.423,2.33-1.345-.821-1.423a.336.336,0,0,1,.354-.5l1.574.3.51-2.641-1.574-.3a3.026,3.026,0,0,0-3.194,4.484Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="txt">Page performance report for your domain</span>
                                                </li>
                                                <li class="list-itm">
                                                    <span class="ico">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.959"
                                                            height="12.463" viewBox="0 0 10.959 12.463">
                                                            <g id="Group_60449" data-name="Group 60449"
                                                                transform="translate(4210.169 -1551.285)">
                                                                <path id="Path_15264" data-name="Path 15264"
                                                                    d="M227.356,465.77l.822-1.423,2.329,1.345-.821,1.422a.336.336,0,0,0,.354.5l1.574-.3.51,2.641-1.574.3A3.026,3.026,0,0,1,227.356,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15265" data-name="Path 15265"
                                                                    d="M225.933,475.387l-.822,1.423-2.33-1.345.822-1.423a.336.336,0,0,0-.355-.5l-1.574.3-.509-2.641,1.574-.3A3.026,3.026,0,0,1,225.933,475.387Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15266" data-name="Path 15266"
                                                                    d="M226,465.77l-.822-1.423-2.329,1.345.821,1.422a.337.337,0,0,1-.355.5l-1.574-.3-.508,2.641,1.573.3A3.026,3.026,0,0,0,226,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15267" data-name="Path 15267"
                                                                    d="M227.287,475.387l.821,1.423,2.33-1.345-.821-1.423a.336.336,0,0,1,.354-.5l1.574.3.51-2.641-1.574-.3a3.026,3.026,0,0,0-3.194,4.484Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="txt">Competitor dashboard for 1 competitor</span>
                                                </li>
                                                <li class="list-itm">
                                                    <span class="ico">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.959"
                                                            height="12.463" viewBox="0 0 10.959 12.463">
                                                            <g id="Group_60449" data-name="Group 60449"
                                                                transform="translate(4210.169 -1551.285)">
                                                                <path id="Path_15264" data-name="Path 15264"
                                                                    d="M227.356,465.77l.822-1.423,2.329,1.345-.821,1.422a.336.336,0,0,0,.354.5l1.574-.3.51,2.641-1.574.3A3.026,3.026,0,0,1,227.356,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15265" data-name="Path 15265"
                                                                    d="M225.933,475.387l-.822,1.423-2.33-1.345.822-1.423a.336.336,0,0,0-.355-.5l-1.574.3-.509-2.641,1.574-.3A3.026,3.026,0,0,1,225.933,475.387Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15266" data-name="Path 15266"
                                                                    d="M226,465.77l-.822-1.423-2.329,1.345.821,1.422a.337.337,0,0,1-.355.5l-1.574-.3-.508,2.641,1.573.3A3.026,3.026,0,0,0,226,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15267" data-name="Path 15267"
                                                                    d="M227.287,475.387l.821,1.423,2.33-1.345-.821-1.423a.336.336,0,0,1,.354-.5l1.574.3.51-2.641-1.574-.3a3.026,3.026,0,0,0-3.194,4.484Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="txt">Website on-page SEO reports for 5 pages</span>
                                                </li>
                                                <li class="list-itm">
                                                    <span class="ico">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.959"
                                                            height="12.463" viewBox="0 0 10.959 12.463">
                                                            <g id="Group_60449" data-name="Group 60449"
                                                                transform="translate(4210.169 -1551.285)">
                                                                <path id="Path_15264" data-name="Path 15264"
                                                                    d="M227.356,465.77l.822-1.423,2.329,1.345-.821,1.422a.336.336,0,0,0,.354.5l1.574-.3.51,2.641-1.574.3A3.026,3.026,0,0,1,227.356,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15265" data-name="Path 15265"
                                                                    d="M225.933,475.387l-.822,1.423-2.33-1.345.822-1.423a.336.336,0,0,0-.355-.5l-1.574.3-.509-2.641,1.574-.3A3.026,3.026,0,0,1,225.933,475.387Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15266" data-name="Path 15266"
                                                                    d="M226,465.77l-.822-1.423-2.329,1.345.821,1.422a.337.337,0,0,1-.355.5l-1.574-.3-.508,2.641,1.573.3A3.026,3.026,0,0,0,226,465.77Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                                <path id="Path_15267" data-name="Path 15267"
                                                                    d="M227.287,475.387l.821,1.423,2.33-1.345-.821-1.423a.336.336,0,0,1,.354-.5l1.574.3.51-2.641-1.574-.3a3.026,3.026,0,0,0-3.194,4.484Z"
                                                                    transform="translate(-4431.334 1086.938)"
                                                                    fill="currentcolor" fill-rule="evenodd" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span class="txt">Limited access to keyword research tool</span>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="sep-wrp">
                <h3 class="sep-title">OR</h3>
            </div>
            <div class="sep-msg"> <span>Do you Already Have an Activation Code?</span> <span> <a
                        (click)="enterActivationCode()">Please click here</a> </span></div>

            <div class="signup-action-footer">
                <a (click)="goToPreviousStep()" class="btn btn-primary actn-arrows prev">
                    <span class="ico">
                        <img src="../../../../assets/img/btn-arrow-prev.svg" alt="next">
                    </span>
                    <span class="txt">Back</span>

                </a>

                <a (click)="isLoading || this.selectedPlanId === null ? null : goToNextStep()"
                    [class.disabled]="isLoading || this.selectedPlanId === null"
                    class="btn btn-primary actn-arrows next"
                    [attr.disabled]="isLoading || this.selectedPlanId === null ? true : null">
                    <span class="txt">Next</span>
                    <span class="ico">
                        <img src="../../../../assets/img/btn-arrow.svg" alt="next">
                    </span>
                </a>
            </div>
        </div>
    </div>
</form>