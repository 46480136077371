<div class="email-verify reset-pass-expired">
    <div class="container custom-container">
        <div class="inner-sec">
            <div class="success-ico">
                <img src="..\assets\img\logo.svg" alt="Warning Icon">
            </div>
        <div class="verifiction verification-failed">Password Reset Link Expired!</div>
        <div class="text verification-failed-text">Your password reset link has expired.<br> Please request a new link to reset your password.</div>
        <!-- <div class="text bold">Thank you!</div>   -->
    </div>
          
    </div>
 
