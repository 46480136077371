import { Component } from '@angular/core';

@Component({
  selector: 'app-email-verify-failed',
  templateUrl: './email-verify-failed.component.html',
  styleUrl: './email-verify-failed.component.scss'
})
export class EmailVerifyFailedComponent {

}
