import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignupPersonalInfoComponent } from './sign-up/signup-personal-info/signup-personal-info.component';
import { SignupSubscriptionPlanComponent } from './sign-up/signup-subscription-plan/signup-subscription-plan.component';
import { SignupBillingAndPaymentComponent } from './sign-up/signup-billing-and-payment/signup-billing-and-payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from '../../shared/shared.module';
import { SignUpActivationCodeModalComponent } from './sign-up/sign-up-activation-code-modal/sign-up-activation-code-modal.component';
import { PaymentComponent } from './payment/payment.component';
import { NgxStripeModule, StripePaymentElementComponent } from 'ngx-stripe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PaymentpopupComponent } from '../../shared/paymentpopup/paymentpopup.component';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailVerfyComponent } from './email-verfy/email-verfy.component';
import { environment } from '../../../environment/environment';
import { EmailVerifyFailedComponent } from './email-verify-failed/email-verify-failed.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AlreadyVerifiedComponent } from './already-verified/already-verified.component';
import { PaymentSubscriptionConfirmationComponent } from './payment-subscription-confirmation/payment-subscription-confirmation.component';
import { ResetPasswordExpiredComponent } from './reset-password-expired/reset-password-expired.component';


@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    SignupPersonalInfoComponent,
    SignupSubscriptionPlanComponent,
    SignupBillingAndPaymentComponent,
    SignUpActivationCodeModalComponent,
    PaymentComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    EmailVerfyComponent,
    EmailVerifyFailedComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    AlreadyVerifiedComponent,
    PaymentSubscriptionConfirmationComponent,
    ResetPasswordExpiredComponent,
   
  ],
  
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    SharedModule,
    NgbModule,
    StripePaymentElementComponent,
    NgxStripeModule.forRoot(environment.STRIPE_KEY),
    NgbAccordionModule,
   
  ],
 
})
export class AuthenticationModule { }
