import { Component } from '@angular/core';

@Component({
  selector: 'app-already-verified',
  templateUrl: './already-verified.component.html',
  styleUrl: './already-verified.component.scss'
})
export class AlreadyVerifiedComponent {

}
