<div class="email-verify">
    <div class="container custom-container">
        <div class="inner-sec">
            <div class="success-ico">
                <img src="../../../../assets/img/logo.svg" alt="tick">
            </div>
        <div class="verifiction verification-failed">Verification Link Expired!</div>
        <div class="text verification-failed-text">Your email verification link has expired.  <br>Please request a new one to verify your email.</div>
        <!-- <div class="text bold">Thank you!</div>   -->
    </div>
          
    </div>
   </div>
