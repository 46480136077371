import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StepService } from '../../../shared/step.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
})
export class SignUpComponent implements OnInit {
showmodal:any;
  currentStep: string = 'personal-info';
  steps = ['personal-info', 'subscription-plan', 'billing-and-payment'];

  constructor(private router: Router, private route: ActivatedRoute, private stepService: StepService) { }

  ngOnInit(): void {
    this.stepService.currentStep$.subscribe(step => {
      this.currentStep = step;
      console.log('Current Step:', this.currentStep);
    });

    const storedCurrentStep = sessionStorage.getItem('currentStep')
    if (storedCurrentStep) {
      this.currentStep = storedCurrentStep;
    }
    this.route.queryParams.subscribe(params => {
      console.log(params)
      if (params['modal'] === 'showSuccessModal') {
        this.showmodal = true
        this.currentStep = 'billing-and-payment';
      }
    });
  }

  isActive(step: string): boolean {
    return this.currentStep === step;
  }

  isCompleted(step: string): boolean {
    const currentIndex = this.steps.indexOf(this.currentStep);
    const stepIndex = this.steps.indexOf(step);
    return stepIndex < currentIndex;
  }

}
