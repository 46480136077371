<div *ngIf="loader">
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '30px', marginBottom: '10px' ,
    borderTopLeftRadius: '10px', 
borderTopRightRadius: '10px',
borderBottomLeftRadius: '10px',
borderBottomRightRadius: '10px',

}"></ngx-skeleton-loader>

<ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '30px', marginBottom: '10px' ,
    borderTopLeftRadius: '10px', 
borderTopRightRadius: '10px',
borderBottomLeftRadius: '10px',
borderBottomRightRadius: '10px',

}"></ngx-skeleton-loader>
</div>
@if (elementsOptions.clientSecret) {
    <ngx-stripe-elements
    [stripe]="stripe"
   [elementsOptions]="elementsOptions"
  >
    <ngx-stripe-payment [options]="paymentElementOptions" />
  </ngx-stripe-elements>
  } 
  
 

