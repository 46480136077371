import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignupPersonalInfoComponent } from './sign-up/signup-personal-info/signup-personal-info.component';
import { SignupSubscriptionPlanComponent } from './sign-up/signup-subscription-plan/signup-subscription-plan.component';
import { SignupBillingAndPaymentComponent } from './sign-up/signup-billing-and-payment/signup-billing-and-payment.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmailVerfyComponent } from './email-verfy/email-verfy.component';
import { EmailVerifyFailedComponent } from './email-verify-failed/email-verify-failed.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AlreadyVerifiedComponent } from './already-verified/already-verified.component';
import { PaymentSubscriptionConfirmationComponent } from './payment-subscription-confirmation/payment-subscription-confirmation.component';
import { ResetPasswordExpiredComponent } from './reset-password-expired/reset-password-expired.component';
import { canDeactivateGuard } from '../../core/helpers/can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignUpComponent,
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:token',
    component: ChangePasswordComponent
  },
  {
    path: 'reset-password-failed',
    component: ResetPasswordExpiredComponent
  },
  {
    path: 'email-verified',
    component: EmailVerfyComponent
  },
  {
    path: 'email-verification-failed',
    component: EmailVerifyFailedComponent
  },
  {
    path: 'email-already-verified',
    component: AlreadyVerifiedComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionsComponent
  },
  {
    path: 'confirm-payment/:subscriptionId/:client_secret',
    component: PaymentSubscriptionConfirmationComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
