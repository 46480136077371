import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivationService } from '../../../service/activation.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../service/auth-service';
import { Router } from '@angular/router';
import { FormFieldService } from '../../../../shared/form-field.service';
import { StepService } from '../../../../shared/step.service';

@Component({
  selector: 'app-sign-up-activation-code-modal',
  templateUrl: './sign-up-activation-code-modal.component.html',
  styleUrl: './sign-up-activation-code-modal.component.scss'
})
export class SignUpActivationCodeModalComponent implements OnInit {
  public activationCodeForm!: UntypedFormGroup;
  loading: boolean = false;

  // @Input() paymentIntentId: any;
  // @Input() productId: any;
  success: any = {};
  error: any;
  // editable: boolean = true;
  // @Output() coupneCode = new EventEmitter<any>;
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toasterService: ToastrService,
    private router: Router,
    private formfieldService: FormFieldService,
    private stepservice: StepService

  ) { }

  ngOnInit(): void {
    this.activationCodeForm = this.formBuilder.group({
      code: [this.success.couponCode ? this.success.couponCode : '', [Validators.required]],
    });
  }

  onSubmit(): void {

    if (this.activationCodeForm.invalid) {
      this.activationCodeForm.markAllAsTouched();
      return;
    }

    if (this.activationCodeForm.valid) {
      console.log(this.activationCodeForm.value);

      this.signup(this.activationCodeForm.get('code')?.value);

    }
  }


  signup(code: any): void {
    this.loading = true;

    const formData = new FormData();
    let form: any = sessionStorage.getItem('personalInfoFormData');
    // let paymentAddress :any = sessionStorage.getItem('billingFormData')
    // let paymentAddressForm = JSON.parse(paymentAddress)
    let img: any = sessionStorage.getItem('dropImageData');
    let img1: any = JSON.parse(img)
    let dropImage = img1 ? this.base64ToFile(img1.file, img1.name) : null;

    let formUpdate = JSON.parse(form)
    formData.append('firstName', formUpdate.firstName);
    formData.append('lastName', formUpdate.lastName);
    formData.append('email', formUpdate.email);

    let phone = formUpdate.phoneNumber;
    let cleanPhoneNumber = phone?.number.replace(/\D/g, ''); // Remove non-digit characters
    if (cleanPhoneNumber.startsWith('0')) {
      cleanPhoneNumber = cleanPhoneNumber.slice(1); // Remove leading zero
    }
    formData.append('phone', cleanPhoneNumber);

    let countryCode = phone?.dialCode;
    formData.append('countryCode', countryCode);

    formData.append('password', formUpdate.password);
    formData.append('confirmPassword', formUpdate.confirmPassword);

    formData.append('primaryColor', formUpdate.primaryColor);
    formData.append('secondaryColor', formUpdate.secondaryColor);
    // formData.append('city',paymentAddressForm.city)
    // formData.append('state',paymentAddressForm.state)

    // formData.append('country',paymentAddressForm.country.country)

    // formData.append('apartment',paymentAddressForm.apartment)
    // formData.append('address',paymentAddressForm.address)

    // formData.append('pincode',paymentAddressForm.postalCode)
    if (dropImage) {
      formData.append('logo', dropImage);
    }

    formData.append('activationCode', code);

    this.authService.signupWithCode(formData).subscribe(
      (res: any) => {
        if (res.success) {

          // clearing fields
          localStorage.clear();
          sessionStorage.clear();
          this.formfieldService.clearAllData();
          this.stepservice.clearAll();

          // token section
          let token = res?.token?.accessToken;
          localStorage.setItem('token', res.token?.accessToken);
          localStorage.setItem('refreshToken', res.token?.refreshToken);
          localStorage.setItem('expiresIn', res.token?.expiresIn);

          this.activeModal.close();
          this.loading = false;

          if (token) {
            this.getUserDtl(token);
          }
        }
      }, (err: any) => {
        console.log(err)
        this.toasterService.error(err, "Error!", {
          progressBar: true,
          // toastClass: "toast ngx-toastr",
          closeButton: true,
          timeOut: 3000,
        });
        this.loading = false;
      }
    );

  }

  base64ToFile(base64String: string, fileName: string): File {
    // Remove the data URL scheme part if present
    const base64Pattern = /^data:(.*?);base64,/;
    const base64Data = base64String.replace(base64Pattern, '');

    // Convert base64 string to a binary string
    const binaryString = atob(base64Data);

    // Create a byte array
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: 'image/png' });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: 'image/png' });

    return file;
  }

  getUserDtl(token: any) {
    this.authService.getCurrentUserSignup(token).then((res: any) => {
      if (res.success) {
        // this.spinner.hide()
        // modal.close()
        this.authService.userProfile = res.user
        // this.profile.userProfile = res.user
        localStorage.setItem('role', res.user.role_id);
        localStorage.setItem('email', res.user.email);
        localStorage.setItem('UserId', res.user.id);
        // console.log( this.authService.userProfile)

        if (res.user.role_id == 1) {
          this.router.navigate(['/admin/dashboard']);
        } else {
          this.router.navigate(['/user/statistics']);
        }
      }

    }, (err: any) => {
      // this.loading = false;
      this.toasterService.error(err, "Error!", {
        progressBar: true,
        // toastClass: "toast ngx-toastr",
        closeButton: true,
        timeOut: 3000,
      });
    })
  }


  close(): void {
    this.activeModal.close();
  }

  get f() {
    return this.activationCodeForm.controls;
  }

}