import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-verfy',
  templateUrl: './email-verfy.component.html',
  styleUrl: './email-verfy.component.scss'
})
export class EmailVerfyComponent implements OnInit{

  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log('EmailVerfyComponent initialized');
    let loken = localStorage.getItem('token');
    if(loken){
      localStorage.clear();
    }
    setTimeout(() => {
      this.router.navigate(['/signin']);
    }, 10000);
  }
}
