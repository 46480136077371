<div class="email-verify">
    <div class="container custom-container">
        <div class="inner-sec">
            <div class="success-ico">
                <img src="../../../../assets/img/logo.svg" alt="tick">
            </div>
        <div class="verifiction">Email Verified!</div>
        <div class="text">Thank you for verifying your email address. Your account is now fully <br>activated and ready to use.</div>
        <!-- <div class="text bold">Thank you!</div>   -->
         <div class="two-btn-sec">
            <div class="home-btn">
                <a href="/home"  class="login-btn">Home</a>
            </div>
            <div class="home-btn">
                <a href="/signin" class="login-btn scnd-btn">
                    Login
                </a>
            </div>
         </div>
    </div>
          
    </div>
   </div>
