import { CanDeactivateFn } from '@angular/router';

export const canDeactivateGuard: CanDeactivateFn<unknown> = (component, currentRoute, currentState, nextState) => {


  if (sessionStorage.getItem('paymentInProgress')) {
    // Use the built-in `confirm` method to show a dialog
    const confirmation = window.confirm('You have an ongoing payment. Do you really want to leave the page?');

    if (confirmation) {
      // If the user confirms, allow navigation and clear the flag
      sessionStorage.removeItem('paymentInProgress');
      return true;
    } else {
      // If the user cancels, block the navigation
      return false;
    }
  }
  return true;
};
